import { useInputs } from './useInputs'

export const usePerson = () => {
	const { inputs: person, setInputs: setPerson } = useInputs({
		name: {
			placeholder: 'Имя',
			value: '',
			required: true,
			minlength: 2,
			onlyText: true
		},
		surname: {
			placeholder: 'Фамилия',
			value: '',
			required: true,
			minlength: 2,
			onlyText: true
		},
		middleName: {
			placeholder: 'Отчество',
			value: '',
			onlyText: true
		},
		phone: {
			placeholder: 'Телефон',
			value: '',
			isTelephone: true,
			/* eslint-disable-next-line */
			pattern: '^(\\+7|8|7)(\\d{10}$| \\(\\d{3}\\) \\d{3}-\\d{2}-\\d{2})'
		},
		birthdate: {
			placeholder: 'Дата рождения',
			value: '',
			type: 'date'
		}
	})

	return {
		person,
		setPerson
	}
}
