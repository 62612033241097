<template lang="pug">
TheDetail(
	:groups="groups"
	title="Сотрудник"
	title-new="Создание сотрудника"
	@remove="removeWorker"
	@save="saveWorker"
	ref="detail"
)
teleport(to="#teleportInDetail")
	BaseGroup(title='Организация')
		BaseCombo(
			placeholder='Выберите организацию'
			v-model:search="organizationSearch"
			:options="organizations"
			@update:search="searchOrganization"
			@update:value="organizationId = +$event"
			@click="searchOrganization('')"
		)
ModalJobs(v-model:show="modal" @create="replaceJob")
</template>

<script>
import { onMounted, reactive, ref } from 'vue'

import { useApi } from '../modules/api'
import { usePerson } from '../modules/composition/inputs/usePerson'
import { useUser } from '../modules/composition/inputs/useUser'
import { useJobs } from '../modules/composition/useJobs'
import { useDetail } from '../modules/composition/useDetail'

import { getInputValues } from '../utils/getInputValues'

import TheDetail from '../components/TheDetail'
import BaseGroup from '@/components/ui/BaseGroup'
import BaseCombo from '@/components/ui/BaseCombo'
import ModalJobs from '../components/modals/ModalJobs'

export default {
	name: 'WorkerDetail',
	components: { TheDetail, ModalJobs, BaseGroup, BaseCombo },
	setup () {
		const { idDetail, isNew, notFoundDetail, saveDetail, removeDetail } = useDetail()

		const detail = ref(null)

		const organizationId = ref(0)
		const organizationSearch = ref('')
		const organizations = ref([])

		const searchOrganization = async (value) => {
			const { get, data } = useApi(`/organization/search?limit=5&search=${value}`)
			await get()
			organizations.value = {}
			data.value.forEach(el => {
				organizations.value[el.id] = el.name
				detail.value.changeInitialState()
			})
		}

		const saveWorker = async () => {
			const user = getInputValues(groups.user.inputs)
			await saveDetail({
				endpoint: '/worker/create',
				body: {
					...getInputValues(groups.general.inputs),
					...user,
					authorities: typeof user.authorities === 'object' ? user.authorities : [user.authorities],
					organization: {
						id: organizationId.value
					}
				},
				jobs,
				createJobApi
			})
		}

		const removeWorker = async () => {
			await removeDetail(`/worker/${idDetail}`)
		}

		const createJobApi = async ({ id, orgId, postId, type }) => {
			const { post, data } = useApi('/worker/createPlaceOfWork')

			await post({
				workerId: id || idDetail,
				orgId,
				postId,
				type
			})

			return data.value?.id
		}

		const { jobs, setJobs, replaceJob } = useJobs({
			deleteEndpoint: '/worker/deletePlaceOfWork',
			createJobApi,
			idWorker: idDetail,
			isNew
		})

		const { user, setUser } = useUser()
		const { person, setPerson } = usePerson()

		const groups = reactive({
			general: {
				title: 'Общие данные',
				inputs: person
			},
			user: {
				title: 'Данные о пользователе',
				inputs: user
			}
		})

		onMounted(async () => {
			if (!isNew) {
				const { get, data, error } = useApi(`/worker/${idDetail}`)

				await get()

				if (error.value) return notFoundDetail()

				const {
					name, surname, middleName, phone, birthdate,
					login, authorities,
					placeOfWorks,
					organization
				} = data.value

				setPerson({ name, surname, middleName, phone, birthdate })
				setUser({ login, authorities })

				placeOfWorks.forEach(item => {
					const { id, name: title, post: status, type } = item
					setJobs({ id, title, status, type })
				})

				organizationSearch.value = organization.name
				organizationId.value = organization.id
			}
		})

		const modal = ref(false)

		return {
			detail,

			organizationId,
			organizationSearch,
			organizations,
			searchOrganization,

			groups,
			modal,

			replaceJob,

			saveWorker,
			removeWorker
		}
	}
}
</script>
