import { reactive } from 'vue'

export const useInputs = (inputs) => {
	const wrapper = reactive(inputs)

	const setInputs = (fields) => {
		Object.entries(fields).forEach(([key, value]) => {
			if (wrapper[key]) {
				wrapper[key].value = value
			}
		})
	}

	return {
		inputs: wrapper,
		setInputs
	}
}
