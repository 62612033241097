import { reactive } from 'vue'
import { roles } from '../../constants/roles'

export const useUser = () => {
	const user = reactive({
		login: {
			placeholder: 'Логин',
			value: '',
			minlength: 3
		},
		authorities: {
			placeholder: 'Роль',
			value: '',
			options: roles
		},
		password: {
			placeholder: 'Пароль',
			value: null,
			type: 'password',
			minlength: 4
		}
	})

	const setUser = ({ login, authorities }) => {
		user.login.value = login
		user.authorities.value = authorities.length ? authorities.map(role => role.name) : ''
	}

	return {
		user,
		setUser
	}
}
