import { useApi } from '../api'
import { reactive } from 'vue'
import { useAlert } from './useAlert'

export const useJobs = ({ createJobApi, deleteEndpoint, workerId, isNew }) => {
	const { errorAlert, infoAlert } = useAlert()

	const jobs = reactive({})

	const setJobs = ({ id, title, status, type }) => {
		jobs[id] = { title, status, type }
	}

	const deleteField = (id) => {
		delete jobs[id]
	}

	const createJob = async ({ orgId, postId, type, title, status }) => {
		if (!isNew) {
			const id = await createJobApi({ orgId, postId, type })

			if (id) {
				infoAlert('Место работы успешно сохранено')

				setJobs({ id, title, status, type })
			} else {
				errorAlert('Ошибка в выборе места работы!')
			}
		} else {
			setJobs({ id: `${orgId}---${postId}`, title, status, type })
		}
	}

	const deleteJob = async (id, type) => {
		if (!isNew) {
			const { del, error } = useApi(deleteEndpoint)

			await del('', {
				id,
				type,
				workerId
			})

			if (error.value) {
				errorAlert(`Ошибка в удалении места работы! ${error.value?.message}`)
			} else {
				infoAlert('Место работы успешно удалено')

				deleteField(id)
			}
		} else {
			deleteField(id)
		}
	}

	const replaceJob = async ({ orgId, postId, type, title, status }) => {
		// у сотрудника только одно место работы, при добавлении нового места работы старые очищаются
		if (!isNew) {
			const id = await createJobApi({ orgId, postId, type })

			if (id) {
				infoAlert('Место работы успешно сохранено')

				Object.keys(jobs).forEach(id => deleteField(id))
				setJobs({ id, title, status, type })
			} else {
				errorAlert('Ошибка в выборе места работы!')
			}
		} else {
			Object.keys(jobs).forEach(id => deleteField(id))
			setJobs({ id: `${orgId}---${postId}`, title, status, type })
		}
	}

	return {
		jobs,
		setJobs,
		createJob,
		deleteJob,
		replaceJob
	}
}
